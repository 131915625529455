import React from "react";
import { Flex, Image, Box, Text } from "@chakra-ui/react";

const Profile = (props) => {
  const { photo, name, position, affiliation, species } = props;

  return (
    <Flex
      display="col"
      maxW="2xs"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      height="md"
      align="top"
    >
      {photo && (
        <Image
          src={photo}
          alt={name}
          overflow="hidden"
          width="100%"
          fit="cover"
          top="0"
        />
      )}
      {!photo && <Box backgroundColor="gray.200" height="254px" />}
      <Box p="3" align="left">
        <Box fontWeight="bold" lineHeight="tight">
          {name}
        </Box>
        <Box>
          <Text>
            {position} <em>{species}</em>
          </Text>
          <Text fontSize="sm">{affiliation}</Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default Profile;
